<template>
    <div class="page padd30">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="osn" class="sInput" placeholder="请输入合同编号" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getRecord" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div><label for="">合同编号：</label>{{item.osn}}</div>
                    <div class="flex">
                        <div><label for="">客户名称：</label>{{item.name}}</div>
                        <div><label for="">跟进人：</label>{{item.staff_uid}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">金额：</label>{{item.amount}}</div> 
                        <div><label for="">支付方式：</label>{{item.method}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">收款批次：</label>{{item.batch}}</div>
                        <div><label for="">回款时间：</label>{{item.receipt_time}}</div>
                    </div>
                    <div>
                        <div><label for="">回款备注：</label>{{item.doc}}</div>
                    </div>
                </div>
                <div class="btn_con">
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->

            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
        
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            osn: "",
            data:[],
            methodArr: ["公户打款","微信","支付宝","刷卡","现金"],

            // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({"osn": this.osn,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/order/orderAdd" || this.fromPath == '/scrm_wap/order/orderDetail') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.osn=data.osn
        }
        this.getRecord();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getRecord();
        },
        // 获取回款列表
        getRecord() {
            this.dataLoadStop = true;
            requestXml("/scrm/Order/getPaymentList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].receipt_time = getDateTime(res.list[i].receipt_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    res.list[i].method = this.methodArr[res.list[i].method - 1];
                }
                this.data = res.list;
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{ "type":2,"osn": this.osn,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getRecord();
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/delPayment","POST",(res) => {
                console.log(res);
                if(res == "success"){
                    this.data = [];
                    this.getRecord();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style></style>
